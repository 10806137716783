// npm
import React, { memo, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Box, Container, alpha, styled, useTheme } from '@mui/material'

import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
  EffectFade,
} from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/effect-fade'
import { Swiper, SwiperSlide } from 'swiper/react'

// src
import ContentSectionBgImage from './ContentSectionBgImage'
import DirectionButton from '@atoms/buttons/DirectionButton'

const SwiperContainer = styled(Box)(({ theme }) => ({
  ['.quotes-swiper']: {
    position: 'relative',
  },
  ['.swiper-pagination-bullets.swiper-pagination-horizontal']: {
    width: 'auto',
  },
  ['.swiper-pagination-bullet']: {
    width: '8px',
    height: '8px',
    background: 'rgba(255,255,255,0.7)',
    opacity: 1,
    position: 'relative',
    margin: '0 10px !important' as any,
    ['&:before']: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate3d(-50%,-50%,0)',
      content: '""',
      display: 'block',
      width: '22px',
      height: '22px',
      background: 'none',
      borderRadius: '50%',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: alpha(theme.palette.primary.main, 0),
      transition: theme.transitions.create(['border-color']),
    },
    ['&.swiper-pagination-bullet-active']: {
      ['&:before']: {
        borderColor: alpha(theme.palette.primary.main, 0.8),
      },
    },
  },
}))

const NavContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  marginTop: theme.spacing(-12),
  marginBottom: theme.spacing(4),
  flexDirection: 'row',
}))

const ContentSectionRecentProjects = () => {
  const theme = useTheme()
  const breakpoint = 'md'
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  SwiperCore.use([Autoplay, Pagination, Navigation, EffectFade])

  const data = useStaticQuery<Queries.RecentProjectsSwiperQuery>(graphql`
    query RecentProjectsSwiper {
      allDatoCmsProject(
        limit: 5
        sort: { fields: [meta___createdAt], order: [DESC] }
      ) {
        edges {
          node {
            originalId
            model {
              apiKey
            }
            slug
            snapSectionHero {
              smallHeading
              mainHeadingNode {
                childMarkdownRemark {
                  html
                }
              }
            }
            description
            imageLandscape: heroImage {
              gatsbyImageData(
                width: 1600
                layout: FULL_WIDTH
                imgixParams: { ar: "4:3", fit: "crop", crop: "focalpoint" }
              )
            }
            imagePortrait: heroImage {
              gatsbyImageData(
                width: 1600
                layout: FULL_WIDTH
                imgixParams: { ar: "1:2", fit: "crop", crop: "focalpoint" }
              )
            }
          }
        }
      }
    }
  `)

  return (
    <SwiperContainer>
      <Swiper
        onInit={(swiper) => {
          // @ts-ignore
          swiper.params.navigation.prevEl = prevRef.current
          // @ts-ignore
          swiper.params.navigation.nextEl = nextRef.current
          swiper.navigation.init()
          swiper.navigation.update()
        }}
        pagination={{
          el: '.swiper-pagination-container',
        }}
        slidesPerView={1}
        observer={true}
        loop={true}
        spaceBetween={0}
        observeParents={true}
        autoHeight={true}
        autoplay={{
          delay: 7000,
        }}
        effect="fade"
        speed={1000}
        className="quotes-swiper"
      >
        {data.allDatoCmsProject.edges.map((item: any) => {
          const slide: Queries.DatoCmsProject = item.node
          return (
            <SwiperSlide key={slide.id}>
              {/* <BackgroundPlx parallaxData={parallaxData}>
                <SlideImage image={images} alt="" />
              </BackgroundPlx>
              <FullHeightWrapper>asjkldhasklhd</FullHeightWrapper> */}
              <ContentSectionBgImage
                keyId={slide.id}
                imagePortrait={slide.imagePortrait.gatsbyImageData}
                imageLandscape={slide.imageLandscape.gatsbyImageData}
                subtitle={slide.snapSectionHero[0].smallHeading}
                mainHeading={
                  slide.snapSectionHero[0].mainHeadingNode.childMarkdownRemark
                    .html
                }
                text={slide.description}
                forceHeadingBorder
                button={{
                  label: 'View Project',
                  isInternal: true,
                  internalLink: {
                    slug: slide.slug,
                    model: {
                      apiKey: slide.model.apiKey,
                    },
                  },
                }}
              />
            </SwiperSlide>
          )
        })}
        <Container maxWidth="xl" sx={{ position: 'relative', zIndex: 20 }}>
          <NavContainer>
            <DirectionButton
              direction="left"
              aria-label="previous testimonial"
              ref={prevRef}
            />
            <Box mx={1}>
              <div className="swiper-pagination-container" />
            </Box>
            <DirectionButton
              direction="right"
              aria-label="next testimonial"
              ref={nextRef}
            />
          </NavContainer>
        </Container>
      </Swiper>
    </SwiperContainer>
  )
}

export default memo(ContentSectionRecentProjects)
